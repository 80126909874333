import http from "@/utils/http";

export default {
    /*
     *
     *  内容型 代办事项列表
     * */
    findContentPanelaList(param) {
        return http.get("/paneldeal/content/page/list", param);
    },
    /*
     *
     *  查询待办事项类型
     * */
    getPanelTypeList(param) {
        return http.get("/paneldeal/content/type/list", param);
    },

    /*
     *  修改 内容代办事项 状态
     * */
    modifyContentPandealState(param) {
        return http.put("/paneldeal/content/state/mod", param);
    },

    /*
     *  查询总收入数据
     * */
    queryTotalIncomeMoney(param) {
        return http.get("/panel/income/count/list", param);
    },

    /*
     *  查询组织数量数据
     * */
    queryTotalOrganizationCount(param) {
        return http.get("/panel/org/count/list", param);
    },
    /*
     *  查询组织数量数据 - 组织行业查询
     * */
    queryIndustryOrganizationCount(param) {
        return http.get("/panel/org/orgindustry/count/list", param);
    },

    /*
     * 查询学员数量
     * */
    queryTotalStudentCount(param) {
        return http.get("/panel/student/count/list", param);
    },
    /*
     * 查询学员数量 - 岗位查询
     * */
    queryDutyStudentCount(param) {
        return http.get("/panel/student/duty/count/list", param);
    },

    /*
     *  查询员工数量 - 按照身份
     * */
    queyIdentityEmployeeCount(param) {
        return http.get("/panel/employee/count/list", param);
    },
    /*
     * 查询课件数量
     * */
    queryTotalLessonCount(param) {
        return http.get("/panel/course/count/list", param);
    },
    /*
     *  查询培训统计数据
     * */
    queryTrainStaticData(param) {
        return http.get("/panel/train/count/list", param);
    },
    /**
     * 加载代办事项
     */
    getOrganationWaitingEvent(param) {
        return http.get("/paneldeal/manage/list", param);
    },
    /**
     * 更新代办事项
     */
    updateOrganationWaitingEvent(param) {
        return http.get("/agenda/update", param);
    },
    /**
     * 更新代办事项
     */
    getEventDetail(param) {
        return http.get("/paneldeal/content/detail", param);
    },
    /**
     * 获取操作引导
     */
    fetchOperationGuideData(param) {
        return http.get("/operation/guide/select", param);
    },
    /**
     * 更新操作引导
     */
    updateOperationGuideData(param) {
        return http.post("/operation/guide/update", param);
    },
    /**
     * 获取操作引导
     */
    getOperationProcessGuideData(param) {
        return http.get("/operation/guide/image", param);
    },
    /**
     * 检测现场学习
     */
    checkOffLineLearn(param) {
        return http.get("/plan/check/offline/learn", param);
    },
    /**
     * 分页查询待办事项内容
     */
    getPagePanelMessageList(param) {
        return http.get("/panel/message/list", param);
    },
    /**
     * 查询必办事项 - 20231026-lzb
     */
    getPagePanelMustContentPageList(param) {
        return http.get("/paneldeal/content/page", param);
    },
};
